import instance from "../axios";
import * as endpoints from "./endpoints";
import { USER_REGISTER_ROUTE } from "routes";
import {
  INSTALL_KEY,
  AUTHORIZATION_KEY,
  API_HOST,
  TEAM_PREFIX_LOCAL_STORAGE_KEY,
} from "../constants";

export const signInUser = (payload) => {
  payload["install_key"] = INSTALL_KEY;
  delete instance.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.SIGN_IN_USER_API_PATH, payload, {
        baseURL: API_HOST,
      })
      .then((response) => {
        const { data } = response;
        instance.defaults.headers.common["Authorization"] = data.token;
        resolve(data);
      })
      .catch((err) => {
        if (
          err != null &&
          err.response != null &&
          err.response.status === 401
        ) {
          alert("User is not authorized. Please signup again");
          window.location = USER_REGISTER_ROUTE;
        } else {
          reject(err);
        }
      });
  });
};

export const signUpUser = (payload) => {
  payload["install_key"] = INSTALL_KEY;
  delete instance.defaults.headers.common["Authorization"];
  return instance.post(endpoints.SIGN_UP_USER_API_PATH, payload, {
    baseURL: API_HOST,
  });
};

const setTeamPrefix = (firstName) => {
  if (!firstName) {
    console.warn("[teamName] firstName is NULL");
    return;
  }

  localStorage.setItem(TEAM_PREFIX_LOCAL_STORAGE_KEY, firstName);
};

export const getProfile = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem(AUTHORIZATION_KEY);
    if (token) {
      instance.defaults.headers.common["Authorization"] = token;
      instance
        .get(endpoints.PROFILE_API_PATH, {
          baseURL: API_HOST,
        })
        .then((response) => {
          const { data } = response;
          if (data.username) {
            setTeamPrefix(data.firstName);
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      reject();
    }
  });
};

export const getMatches = (queryString) => {
  let path = endpoints.GET_MATCHES_API_PATH;
  if (queryString) {
    path = path.concat("?", queryString);
  }
  return instance.get(path);
};

export const getMyMatches = (queryString) => {
  let path = endpoints.GET_MY_MATCHES_API_PATH;
  if (queryString) {
    path = path.concat("?", queryString);
  }
  return instance.get(path);
};

export const getMatchDetail = (matchId) => {
  let path = endpoints.GET_MATCH_DETAIL_API_PATH;
  path = path.replace("{}", matchId);
  return instance.get(path);
};

export const getMatchContests = (matchId) => {
  let path = endpoints.GET_MATCH_CONTESTS_API_PATH;
  path = path.replace("{}", matchId);
  return instance.get(path);
};

export const getMatchContestDetail = (contestId) => {
  let path = endpoints.GET_CONTEST_DETAIL_API_PATH;
  path = path.replace("{}", contestId);
  return instance.get(path);
};

export const getMatchContestLeadboard = (contestId) => {
  let path = endpoints.GET_CONTEST_LEADERBOARD_API_PATH;
  path = path.replace("{}", contestId);
  return instance.get(path);
}

export const joinContest = (contestId, payload) => {
  let path = endpoints.JOIN_CONTEST_API_PATH;
  path = path.replace("{}", contestId);
  return instance.post(path, payload);
};

export const createTeam = (payload) => {
  return instance.post(endpoints.CREATE_TEAM_API_PATH, payload);
};

export const getMatchTeams = (matchId) => {
  let path = endpoints.GET_MATCH_TEAMS_API_PATH;
  path = path.replace("{}", matchId);
  return instance.get(path);
};

export const getUserBalance = () => {
  return instance.get(endpoints.USER_BALANCE_API_PATH, { baseURL: API_HOST });
};

export const initiateTransaction = (payload) => {
  return instance.post(endpoints.INITIATE_TRANSACTION_API_PATH, payload, {
    baseURL: API_HOST,
  });
};

export const submitKycDetails = (payload) => {
  return instance.post(endpoints.KYC_DETAILS_API_PATH, payload, {
    baseURL: API_HOST,
  });
};

export const getKycDetails = () => {
  return instance.get(endpoints.KYC_DETAILS_API_PATH, { baseURL: API_HOST });
};

export const updateProfile = (payload) => {
  return instance.post(endpoints.PROFILE_API_PATH, payload, {
    baseURL: API_HOST,
  });
};

export const updateProfilePic = (payload) => {
  return instance.post(endpoints.PROFILE_PIC_API_PATH, payload, {
    baseURL: API_HOST,
  });
};

export const getMyMatchContests = (matchId) => {
  let path = endpoints.GET_MY_MATCH_CONTESTS_API_PATH;
  path = path.replace("{}", matchId);
  return instance.get(path);
};

export const getPaymentMethods = () => {
  return instance.get(endpoints.PAYMENT_METHOD_LIST_API_PATH, {
    baseURL: API_HOST,
  });
};

export const linkPaymentMethod = (payload) => {
  return instance.post(endpoints.PAYMENT_METHOD_LIST_API_PATH, payload, {
    baseURL: API_HOST,
  });
};

export const getWithdrawalRequests = () => {
  return instance.get(endpoints.WITHDRAWAL_REQUEST_API_PATH, {
    baseURL: API_HOST,
  });
};

export const raiseWithdrawalRequest = (payload) => {
  return instance.post(endpoints.WITHDRAWAL_REQUEST_API_PATH, payload, {
    baseURL: API_HOST,
  });
};

export const getTeamDetail = (teamId) => {
  return instance.get(endpoints.TEAM_DETAIL_API_PATH.replace("{}", teamId));
};

export const updateTeam = (payload, teamId) => {
  if (!teamId) {
    teamId = payload.team_id;
    delete payload["team_id"];
  }
  return instance.post(
    endpoints.TEAM_DETAIL_API_PATH.replace("{}", teamId),
    payload
  );
};
