import { lazy } from "react";

import NotFoundPage from "pages/404";

const IndexComponent = lazy(() => import("pages/account"));
const LoginComponent = lazy(() => import("pages/account/login"));
const RegisterComponent = lazy(() => import("pages/account/register"));

const ProfileComponent = lazy(() => import("pages/account/profile"));
const EditProfileComponent = lazy(() => import("pages/account/editProfile"));
const UserTransactionComponent = lazy(() =>
  import("pages/account/transactions")
);

const HomeComponent = lazy(() => import("pages"));

const MyMatchListComponent = lazy(() => import("pages/matchView/myMatches"));
const MyMatchDetailComponent = lazy(() =>
  import("pages/matchView/myMatchDetail")
);
const MatchScoreboardComponent = lazy(() =>
  import("pages/matchView/matchScoreboard")
);

const TeamListComponent = lazy(() => import("pages/teamView/teamsList"));
const TeamCreateComponent = lazy(() => import("pages/teamView/createTeam"));
const TeamPreviewComponent = lazy(() => import("pages/teamView/previewTeam"));

const ContestsListComponent = lazy(() =>
  import("pages/contestView/contestsList")
);
const ContestDetailComponent = lazy(() =>
  import("pages/contestView/contestDetail")
);
const ContestRegisterComponent = lazy(() =>
  import("pages/contestView/contestRegister")
);
const ContestRegisterSuccessComponent = lazy(() =>
  import("pages/contestView/contestRegisterSuccess")
);
const ContestPointSystemComponent = lazy(() =>
  import("pages/contestView/pointSystem")
);

const AddDepositComponent = lazy(() => import("pages/wallet/addDeposit"));

const MyWalletComponent = lazy(() => import("pages/wallet/myWallet"));

const PaymentFailedComponent = lazy(() =>
  import("components/paymentStatus/paymentFailed")
);

const PaymentSuccessComponent = lazy(() =>
  import("components/paymentStatus/paymentSuccess")
);

const PaymentProcessComponent = lazy(() =>
  import("components/paymentStatus/paymentProcess")
);

const PaymentStatusComponent = lazy(() =>
  import("components/paymentStatus/paymentStatus")
);

const CashWithdrawComponent = lazy(() =>
  import("pages/cashWithdrawal/cashWithdraw")
);

const KycVerificationComponent = lazy(() =>
  import("pages/cashWithdrawal/kycVerification")
);

const CashWithdrawalRequestsComponent = lazy(() =>
  import("pages/cashWithdrawal/requests")
);

const TermsConditionsViewComponent = lazy(() =>
  import("pages/termsConditions")
);

const FAQViewComponent = lazy(() => import("pages/faq"));

const PrivacyPolicyViewComponent = lazy(() => import("pages/privacyPolicy"));

const AboutUsViewComponent = lazy(() => import("pages/aboutUs"));

export const INDEX_ROUTE = "/";
export const USER_LOGIN_ROUTE = "/login";
export const USER_REGISTER_ROUTE = "/register";
export const TERMS_CONDITIONS_ROUTE = "/terms-conditions";
export const FAQ_ROUTE = "/faq";
export const PRIVACY_ROUTE = "/privacy-policy";
export const ABOUT_US_ROUTE = "/about-us";

export const USER_PROFILE_ROUTE = "/profile";
export const USER_EDIT_PROFILE_ROUTE = "/editProfile";
export const USER_TRANSACTIONS_ROUTE = "/profile/transactions";
export const TEAM_CREATE_ROUTE = "/match/:matchId/teams/create";
export const TEAM_PREVIEW_ROUTE = "/match/:matchId/teams/preview";

export const TEAM_EDIT_ROUTE = "/teams/edit/:teamId";
export const CONTEST_DETAIL_ROUTE = "/contest/:contestId";

export const MATCH_TEAMS_ROUTE = "/match/:matchId/teams";
export const MATCH_CONTESTS_ROUTE = "/match/:matchId/contests";

export const CONTEST_REGISTER_ROUTE = "/contest/:contestId/register";
export const CONTEST_POINT_SYSTEM_ROUTE = "/contest/:contestId/point-system/";
export const CONTEST_REGISTER_SUCCESS_ROUTE =
  "/contest/:contestId/register/success/";

export const MY_MATCHES_ROUTE = "/my-matches";
export const MY_MATCH_DETAIL_ROUTE = "/my-match/:matchId";
export const MATCH_SCOREBOARD_ROUTE = "/match/:matchId/scoreboard/";

export const MY_WALLET_ROUTE = "/myWallet";
export const ADD_DEPOSIT_ROUTE = "/addDeposit";
export const PAYMENT_FAILED_ROUTE = "/paymentFailed";
export const PAYMENT_SUCCESS_ROUTE = "/paymentSuccess";
export const PAYMENT_PROCESS_ROUTE = "/paymentProcessing";
export const PAYMENT_STATUS_ROUTE = "/paymentStatus";

export const CASH_WITHDRAW_ROUTE = "/cashWithdraw";
export const KYC_VERIFICATION_ROUTE = "/kycVerification";
export const CASH_WITHDRAWAL_REQUESTS_ROUTE = "/cashWithdraw/requests";

export const getMyMatchDetailRoute = (matchId) => {
  return MY_MATCH_DETAIL_ROUTE.replace(":matchId", matchId);
};
export const getMatchTeamListRoute = (matchId) => {
  return MATCH_TEAMS_ROUTE.replace(":matchId", matchId);
};
export const getMatchContestRoute = (matchId) => {
  return MATCH_CONTESTS_ROUTE.replace(":matchId", matchId);
};
export const getMatchScoreboardRoute = (matchId) => {
  return MATCH_SCOREBOARD_ROUTE.replace(":matchId", matchId);
};

export const getMatchTeamCreateRoute = (matchId) => {
  return TEAM_CREATE_ROUTE.replace(":matchId", matchId);
};
export const getMatchTeamEditRoute = (teamId) => {
  return TEAM_EDIT_ROUTE.replace(":teamId", teamId);
};
export const getMatchTeamPreviewRoute = (matchId) => {
  return TEAM_PREVIEW_ROUTE.replace(":matchId", matchId);
};

export const getContestRegisterRoute = (contestId) => {
  return CONTEST_REGISTER_ROUTE.replace(":contestId", contestId);
};
export const getContestDetailRoute = (contestId) => {
  return CONTEST_DETAIL_ROUTE.replace(":contestId", contestId);
};
export const getContestRegisterSuccessRoute = (contestId) => {
  return CONTEST_REGISTER_SUCCESS_ROUTE.replace(":contestId", contestId);
};
export const getContestPointSystemRoute = (contestId) => {
  return CONTEST_POINT_SYSTEM_ROUTE.replace(":contestId", contestId);
};

export const COMMON_ROUTES = [
  {
    exact: true,
    name: "terms-conditions",
    path: TERMS_CONDITIONS_ROUTE,
    component: TermsConditionsViewComponent,
  },
  {
    exact: true,
    name: "faq",
    path: FAQ_ROUTE,
    component: FAQViewComponent,
  },
  {
    exact: true,
    name: "privacy-policy",
    path: PRIVACY_ROUTE,
    component: PrivacyPolicyViewComponent,
  },
  {
    exact: true,
    name: "about-us",
    path: ABOUT_US_ROUTE,
    component: AboutUsViewComponent,
  },
  {
    component: NotFoundPage,
  },
];

export const NON_LOGIN_ROUTES = [
  {
    exact: true,
    name: "index",
    path: INDEX_ROUTE,
    component: IndexComponent,
  },
  {
    exact: true,
    name: "login",
    path: USER_LOGIN_ROUTE,
    component: LoginComponent,
  },
  {
    exact: true,
    name: "login",
    path: USER_REGISTER_ROUTE,
    component: RegisterComponent,
  },
  ...COMMON_ROUTES,
];

export const LOGGED_IN_ROUTES = [
  {
    exact: true,
    name: "home",
    path: INDEX_ROUTE,
    component: HomeComponent,
  },
  {
    exact: true,
    name: "profile",
    path: USER_PROFILE_ROUTE,
    component: ProfileComponent,
  },
  {
    exact: true,
    name: "edit-profile",
    path: USER_EDIT_PROFILE_ROUTE,
    component: EditProfileComponent,
  },
  {
    exact: true,
    name: "user-transactions",
    path: USER_TRANSACTIONS_ROUTE,
    component: UserTransactionComponent,
  },
  {
    exact: true,
    name: "contests",
    path: MATCH_CONTESTS_ROUTE,
    component: ContestsListComponent,
  },
  {
    exact: true,
    name: "contest-detail",
    path: CONTEST_DETAIL_ROUTE,
    component: ContestDetailComponent,
  },
  {
    exact: true,
    name: "team-list",
    path: MATCH_TEAMS_ROUTE,
    component: TeamListComponent,
  },
  {
    exact: true,
    name: "team-create",
    path: TEAM_CREATE_ROUTE,
    component: TeamCreateComponent,
  },
  {
    exact: true,
    name: "team-edit",
    path: TEAM_EDIT_ROUTE,
    component: TeamCreateComponent,
  },
  {
    exact: true,
    name: "team-preview",
    path: TEAM_PREVIEW_ROUTE,
    component: TeamPreviewComponent,
  },
  {
    exact: true,
    name: "contest-register",
    path: CONTEST_REGISTER_ROUTE,
    component: ContestRegisterComponent,
  },
  {
    exact: true,
    name: "contest-register-success",
    path: CONTEST_REGISTER_SUCCESS_ROUTE,
    component: ContestRegisterSuccessComponent,
  },
  {
    exact: true,
    name: "contest-point-system",
    path: CONTEST_POINT_SYSTEM_ROUTE,
    component: ContestPointSystemComponent,
  },
  {
    exact: true,
    name: "my-matches",
    path: MY_MATCHES_ROUTE,
    component: MyMatchListComponent,
  },
  {
    exact: true,
    name: "my-match-detail",
    path: MY_MATCH_DETAIL_ROUTE,
    component: MyMatchDetailComponent,
  },
  {
    exact: true,
    name: "match-scoreboard",
    path: MATCH_SCOREBOARD_ROUTE,
    component: MatchScoreboardComponent,
  },
  {
    exact: true,
    name: "add-deposit",
    path: ADD_DEPOSIT_ROUTE,
    component: AddDepositComponent,
  },
  {
    exact: true,
    name: "my-wallet",
    path: MY_WALLET_ROUTE,
    component: MyWalletComponent,
  },
  {
    exact: true,
    name: "payment-failed",
    path: PAYMENT_FAILED_ROUTE,
    component: PaymentFailedComponent,
  },
  {
    exact: true,
    name: "payment-success",
    path: PAYMENT_SUCCESS_ROUTE,
    component: PaymentSuccessComponent,
  },
  {
    exact: true,
    name: "payment-process",
    path: PAYMENT_PROCESS_ROUTE,
    component: PaymentProcessComponent,
  },
  {
    exact: true,
    name: "redirect-payment",
    path: PAYMENT_STATUS_ROUTE,
    component: PaymentStatusComponent,
  },
  {
    exact: true,
    name: "cash-withdraw",
    path: CASH_WITHDRAW_ROUTE,
    component: CashWithdrawComponent,
  },
  {
    exact: true,
    name: "cash-withdrawal-requests",
    path: CASH_WITHDRAWAL_REQUESTS_ROUTE,
    component: CashWithdrawalRequestsComponent,
  },
  {
    exact: true,
    name: "kyc-verification",
    path: KYC_VERIFICATION_ROUTE,
    component: KycVerificationComponent,
  },
  ...COMMON_ROUTES,
];
