import { useEffect, useState, Suspense } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ActivityIndicator } from "antd-mobile";

import { isEmpty } from "_dash";
import { getProfile } from "actions";
import { FIREBASE_CONFIG } from "./firebase";
import {
  LOGGED_IN_ROUTES,
  NON_LOGIN_ROUTES,
  USER_EDIT_PROFILE_ROUTE,
} from "./routes";

import firebase from "firebase";
import AccountHook from "hooks/userAccount";
import RouteWithSubRoutes from "components/routeWithSubRoutes";

const App = ({ accountData, setAccountData }) => {
  const isLoggedIn = !isEmpty(accountData);
  const [loading, setLoading] = useState(true);
  const [currentRoutes, setCurrentRoutes] = useState([]);

  useEffect(() => {
    firebase.initializeApp(FIREBASE_CONFIG);
  }, []);

  useEffect(() => {
    setLoading(true);
    getProfile()
      .then((response) => {
        const urlParams = new URLSearchParams(window.location.search);
        setCurrentRoutes(LOGGED_IN_ROUTES);
        setAccountData(response);
        setLoading(false);
        if (urlParams.get("login") && !response.isEmailVerified) {
          window.location = USER_EDIT_PROFILE_ROUTE;
        }
      })
      .catch((err) => {
        setCurrentRoutes(NON_LOGIN_ROUTES);
        setLoading(false);
      });
  }, [isLoggedIn]);

  if (loading) {
    return <ActivityIndicator toast animating text="Loading..." />;
  }

  return (
    <Suspense fallback={<ActivityIndicator toast animating />}>
      <BrowserRouter>
        <Switch>
          {currentRoutes.map((route, i) => {
            return <RouteWithSubRoutes key={i} {...route} />;
          })}
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};

export default AccountHook(App);
