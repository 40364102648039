export const KEEPER = "keeper";
export const BOWLER = "bowler";
export const BATSMAN = "batsman";
export const ALLROUNDER = "all_rounder";

export const API_HOST = process.env.REACT_APP_API_HOST;
export const APP_KEY = process.env.REACT_APP_APPLICATION_KEY;
export const INSTALL_KEY = process.env.REACT_APP_INSTALL_KEY;
export const HELP_DESK_EMAIL = process.env.REACT_APP_HELP_DESK_EMAIL;

export const AUTHORIZATION_KEY = "footthi_key";
export const TEAM_LOCAL_STRORAGE_KEY = "profidy_team";
export const TEAM_PREFIX_LOCAL_STORAGE_KEY = "team_prefix";

export const MY_MATCHES_FILTER = [
  {
    label: "Upcoming",
    value: "not_started",
  },
  {
    label: "Live",
    value: "started",
  },
  {
    label: "Result",
    value: "completed",
  },
];

export const PLAYER_ROLE_MAPPING = {
  [KEEPER]: {
    minCount: 1,
    maxCount: 4,
    shortCode: "WK",
  },
  [BOWLER]: {
    minCount: 3,
    maxCount: 6,
    shortCode: "BOW",
  },
  [BATSMAN]: {
    minCount: 3,
    maxCount: 6,
    shortCode: "BAT",
  },
  [ALLROUNDER]: {
    minCount: 1,
    maxCount: 4,
    shortCode: "AR",
  },
};

export const CONTEST_PRIZES_TAB = "prizes";

export const CONTEST_DETAIL_TABS = [
  {
    title: "Prizes",
    value: CONTEST_PRIZES_TAB,
  },
  {
    title: "Leaderboard",
    value: "leaderboard",
  },
];

export const MIN_WITHDRAWABLE_AMOUNT = 200;
export const MAX_WITHDRAWABLE_AMOUNT = 5000;

export const BKASH_TRANSFER_METHOD = "bkash";
export const BANK_TRANSFER_METHOD = "bank-bd";
export const ROCKET_TRANSFER_METHOD = "rocket";

export const WITHDRAWAL_STATUS_MAPPING = {
  0: "Initialized",
  1: "In progress",
  2: "Approved",
  "-1": "Rejected",
  "-2": "Cancelled",
};

export const VERIFIED_KYC_DOCUMENT_STATUS = 1;

export const KYC_DOCUMENT_STATUS_MAPPING = {
  0: "Unverified",
  [VERIFIED_KYC_DOCUMENT_STATUS]: "Verified",
};

export const KYC_DOCUMENT_TYPES = [
  {
    label: "Pan Card",
    type: "pan-card",
    frontIdType: "pan-card-front",
    backIdType: "pan-card-back",
  },
  {
    label: "Aadhar",
    type: "aadhar",
    frontIdType: "aadhaar-front",
    backIdType: "aadhaar-back",
  },
  {
    label: "Passport",
    type: "passport",
    frontIdType: "passport-front",
    backIdType: "passport-back",
  },
  {
    label: "Driving License",
    type: "driving-license",
    frontIdType: "drivers-license-front",
    backIdType: "drivers-license-back",
  },
];

export const CONTEST_LEADERBOARD_KEY_MAPPING = {
  all: {
    display_value: "All TEAMS",
  },
  user: {
    display_value: "Your Teams",
  },
};
