import axios from "axios";

import { USER_LOGIN_ROUTE } from "routes";
import { APP_KEY, API_HOST } from "./constants";

let headers = {
  "X-Application": APP_KEY,
  "Content-Type": "application/json",
};

const axiosInstance = axios.create({
  headers,
  timeout: 6000,
  baseURL: `${API_HOST}/api/`,
});

axiosInstance.interceptors.response.use(undefined, (error) => {
  const { response } = error;
  if (
    error &&
    response.status === 401 &&
    window.location.pathname !== USER_LOGIN_ROUTE
  ) {
    window.location = USER_LOGIN_ROUTE;
  }
  return Promise.reject(error);
});

export default axiosInstance;
